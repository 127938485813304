import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <section className="relative bg-gradient-to-b from-gray-900 to-gray-800 pt-32 pb-48">
      <div className="absolute inset-0 bg-grid-primary/[0.02] bg-[size:20px_20px]" />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h1 className="text-4xl md:text-6xl font-display font-bold text-gray-900 dark:text-white mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-500 to-secondary-500 animate-shine">
              Empower Your Business
            </span>
            <br />
            with AI-Driven Excellence
          </h1>
          <p className="mt-6 text-lg md:text-xl font-extralight tracking-wide text-gray-600 dark:text-gray-300 max-w-3xl mx-auto leading-relaxed">
            Transform data into actionable insights, automate processes, and drive continuous improvement.
          </p>
          
          <div className="mt-10 flex flex-col sm:flex-row gap-4 justify-center">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link 
                to="/demo"
                className="inline-flex items-center px-8 py-3 rounded-full bg-primary-500 text-white font-light shadow-lg shadow-primary-500/25 hover:shadow-primary-500/35 transition-all duration-300"
              >
                Book a Demo
                <svg className="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
              </Link>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link 
                to="/trial"
                className="inline-flex items-center px-8 py-3 rounded-full bg-white dark:bg-gray-800 text-primary-500 font-light shadow-lg hover:shadow-xl transition-all duration-300"
              >
                Test Drive
              </Link>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
