import { motion } from 'framer-motion';
import HubSpotForm from './ui/HubSpotForm';
import { CONFIG } from '../config/env';

export default function FutureSuggestionsForm() {
  return (
    <motion.div 
      className="bg-gray-800/50 backdrop-blur-xl rounded-xl p-8 border border-gray-700/50 w-full max-w-2xl"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      <h3 className="text-2xl font-light text-white mb-4">Suggest a Feature</h3>
      <p className="text-gray-400 font-light mb-8">
        Help shape the future of OpexAI. Share your ideas and feature requests to make our platform even better.
      </p>
      
      <HubSpotForm
        formId={CONFIG.hubspot.featureFormId}
        fields={[
          { name: 'firstname', label: 'Name', required: true },
          { name: 'email', label: 'Email', type: 'email', required: true },
          { name: 'company', label: 'Company', required: true },
          { 
            name: 'company_size', 
            label: 'Number of Employees', 
            type: 'select',
            options: [
              { value: '1-10', label: '1-10' },
              { value: '11-50', label: '11-50' },
              { value: '51-200', label: '51-200' },
              { value: '201-500', label: '201-500' },
              { value: '501-1000', label: '501-1000' },
              { value: '1000+', label: '1000+' }
            ],
            required: true 
          },
          { name: 'message', label: 'Feature Suggestion', type: 'textarea', required: true }
        ]}
        submitButtonText="Submit Suggestion"
      />
    </motion.div>
  );
}
