import React from 'react';
import ContactForm from '../components/Contact';
import { motion } from 'framer-motion';
import PageHero from '../components/PageHero';
import { Helmet } from 'react-helmet-async';
import { 
  CalendarDaysIcon, 
  EnvelopeIcon, 
  ChatBubbleBottomCenterTextIcon 
} from '@heroicons/react/24/outline';

export default function ContactPage() {
  return (
    <>
      <Helmet>
        <title>Contact OpexAI | Get in Touch for AI-Powered Solutions</title>
        <meta name="description" content="Connect with OpexAI to transform your business operations with AI. Schedule a demo, send a message, or get instant support from our AI assistant." />
        <meta property="og:title" content="Contact OpexAI | Get in Touch for AI-Powered Solutions" />
        <meta property="og:description" content="Connect with OpexAI to transform your business operations with AI. Schedule a demo, send a message, or get instant support from our AI assistant." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://opexai.com/contact" />
        <link rel="canonical" href="https://opexai.com/contact" />
      </Helmet>

      <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 mb-12">
        <PageHero 
          subtitle="Get in Touch"
          title="Let's Build Something"
          highlightedText="Great Together"
          description="Transform your operations with AI-powered intelligence and take your business to the next level."
        />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-start">
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="space-y-8 mt-8 md:mt-0"
            >
              <div className="space-y-6">
                <h2 className="text-2xl font-extralight text-gray-900 dark:text-white">
                  Ready to Experience the Future of Operations?
                </h2>
                
                <p className="text-lg font-light text-gray-600 dark:text-gray-300">
                  Connect with us through your preferred channel:
                </p>
              </div>
              
              <div className="grid gap-6">
                {[
                  {
                    title: 'Book a Demo',
                    description: 'Schedule a personalized walkthrough of OpexAI tailored to your needs',
                    icon: CalendarDaysIcon
                  },
                  {
                    title: 'Send a Message',
                    description: 'Share your requirements and our team will get back to you promptly',
                    icon: EnvelopeIcon
                  },
                  {
                    title: 'AI Assistant',
                    description: 'Get instant answers from our AI customer service agent',
                    icon: ChatBubbleBottomCenterTextIcon
                  }
                ].map((item) => (
                  <motion.div
                    key={item.title}
                    whileHover={{ scale: 1.02 }}
                    className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700"
                  >
                    <div className="flex items-start gap-4">
                      <item.icon className="h-6 w-6 text-primary-500" />
                      <div>
                        <h3 className="text-lg font-light text-gray-900 dark:text-white mb-2">
                          {item.title}
                        </h3>
                        <p className="text-gray-600 dark:text-gray-300 font-light">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>

              <div className="bg-gradient-to-r from-primary-500/10 to-secondary-500/10 dark:from-primary-500/20 dark:to-secondary-500/20 p-6 rounded-xl backdrop-blur-sm">
                <p className="text-gray-600 dark:text-gray-300 font-light">
                  Join innovative businesses leveraging OpexAI to revolutionize their operations with cutting-edge AI technology.
                </p>
              </div>
            </motion.div>
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
}
