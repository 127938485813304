import { Link } from 'react-router-dom';
import HubSpotForm from './ui/HubSpotForm';
import { CONFIG } from '../config/env';
import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

function PartnersCarousel({ partners, itemsPerPage }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const totalPages = Math.ceil(partners.length / itemsPerPage);

  useEffect(() => {
    if (!isPaused) {
      const timer = setInterval(() => {
        setCurrentPage((prev) => (prev + 1) % totalPages);
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [isPaused, totalPages, itemsPerPage]);

  return (
    <div
      className="relative overflow-hidden"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={currentPage}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          className="flex justify-center space-x-12"
        >
          {partners
            .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
            .map((partner) => (
              <motion.a
                key={partner.name}
                href={partner.href}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                className="group relative grayscale hover:grayscale-0 transition-all duration-300"
                title={partner.name}
              >
                <img
                  src={partner.logo}
                  alt={partner.name}
                  className="h-24 w-auto opacity-75 group-hover:opacity-100"
                />
              </motion.a>
            ))}
        </motion.div>
      </AnimatePresence>
      <div className="flex justify-center mt-6 space-x-2">
        {Array.from({ length: totalPages }).map((_, idx) => (
          <button
            key={idx}
            onClick={() => setCurrentPage(idx)}
            className={`w-2 h-2 rounded-full transition-all ${
              currentPage === idx ? 'bg-primary-500 w-4' : 'bg-gray-600'
            }`}
          />
        ))}
      </div>
    </div>
  );
}

const footerLinks = {
  product: {
    title: 'Product',
    links: [
      { name: 'Platform', href: '/product' },
      { name: 'Browser Extension', href: '/extension' },
      { name: 'Solutions', href: '/solutions' },
      { name: 'Integrations', href: '/integrations' }
    ]
  },
  company: {
    title: 'Company',
    links: [
      { name: 'Contact', href: '/contact' },
      { name: 'Privacy', href: '/privacy' },
      { name: 'Terms', href: '/terms' }
    ]
  },
  resources: {
    title: 'Resources',
    links: [
      { name: 'Knowledge', href: '/knowledge' },
      { name: 'Roadmap', href: '/roadmap' }
    ]
  }
};
const partners = [
  { 
    name: 'Microsoft Startup Hub', 
    logo: '/logos/microsoft-startups.png',
    href: 'https://www.microsoft.com/en-us/startups'
  },
  { 
    name: 'NVIDIA Inception', 
    logo: '/logos/nvidia-inception.png',
    href: 'https://www.nvidia.com/en-us/startups/'
  },
  { 
    name: 'Google for Startups', 
    logo: '/logos/google-startups.png',
    href: 'https://startup.google.com/'
  },
  { 
    name: 'AWS Startups', 
    logo: '/logos/aws-startups.png',
    href: 'https://aws.amazon.com/startups/'
  }
];
const socialLinks = [
  { 
    name: 'Twitter', 
    href: 'https://twitter.com/opexai-ltd', 
    icon: (
      <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
      </svg>
    )
  },
  { 
    name: 'LinkedIn', 
    href: 'https://www.linkedin.com/company/opexai-ltd', 
    icon: (
      <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
      </svg>
    )
  },
  { 
    name: 'Facebook', 
    href: 'https://facebook.com/opexai', 
    icon: (
      <svg className="h-6 w-6 text-gray-400 hover:text-primary-500" fill="currentColor" viewBox="0 0 24 24">
      <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" />
    </svg>
    )
  }
];
export default function Footer() {

  return (
    <footer className="bg-white dark:bg-gray-900 border-t border-gray-100 dark:border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Partners Banner */}
        <div className="py-8 border-b border-gray-100 dark:border-gray-800">
  <div className="flex flex-col items-center space-y-6">
    <span className="text-xl font-medium bg-gradient-to-r from-primary-500 to-secondary-500 text-transparent bg-clip-text">
      Accelerated by Industry Leaders
    </span>
    <div className="w-full">
      <div className="hidden lg:block">
        <div className="flex items-center justify-center space-x-12">
          {partners.map((partner) => (
            <motion.a
              key={partner.name}
              href={partner.href}
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              className="group relative grayscale hover:grayscale-0 transition-all duration-300"
              title={partner.name}
            >
              <img
                src={partner.logo}
                alt={partner.name}
                className="h-24 w-auto opacity-75 group-hover:opacity-100"
              />
            </motion.a>
          ))}
        </div>
      </div>
      <div className="lg:hidden md:block hidden">
        <PartnersCarousel partners={partners} itemsPerPage={2} />
      </div>
      <div className="md:hidden">
        <PartnersCarousel partners={partners} itemsPerPage={1} />
      </div>
    </div>
  </div>
</div>

        {/* Main Footer Content */}
        <div className="py-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 flex-col-reverse md:flex-row">
          {/* Brand Section */}
          <div className="lg:col-span-2">
            <div className="flex items-center space-x-2 mb-2">
              <img src="/logo.png" alt="OpexAI" className="h-8 md:h-18 lg:h-12 w-auto" />
            </div>
            <p className="text-gray-600 dark:text-gray-400 font-light mb-2 max-w-sm">
              Transforming operations with AI-powered intelligence for the modern enterprise
            </p>
            
            {/* Social Links */}
            <span className="text-sm text-gray-600 dark:text-gray-400 font-light">Follow Us</span>
<div className="flex space-x-4 mb-2">
  {socialLinks.map((social) => (
    <a
      key={social.name}
      href={social.href}
      target="_blank"
      rel="noopener noreferrer"
      className="text-gray-400 hover:text-primary-500 transition-colors duration-200"
    >
      <span className="sr-only">{social.name}</span>
      {social.icon}
    </a>
  ))}
</div>
<span className="text-sm text-gray-600 dark:text-gray-400 font-light">Get the latest News and Product updates</span>
            {/* Newsletter */}
            <HubSpotForm 
  formId={CONFIG.hubspot.newsletterFormId}
  fields={[
    {
      name: 'email',
      type: 'email',
      required: true
    },
    {
      name: 'privacy_consent',
      type: 'checkbox',
      required: true
    },
    {
      name: 'communications_consent',
      type: 'checkbox',
      required: false
    }
  ]}
  submitButtonText="Subscribe"
  variant="newsletter"

/>

          </div>

          {/* Links Sections */}
          {Object.values(footerLinks).map((section) => (
            <div key={section.title}>
              <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-4">
                {section.title}
              </h3>
              <ul className="space-y-3">
                {section.links.map((link) => (
                  <li key={link.name}>
                    <Link
                      to={link.href}
                      className="text-gray-600 dark:text-gray-400 hover:text-primary-500 dark:hover:text-primary-400 font-light text-sm"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom Bar */}
        <div className="py-6 border-t border-gray-100 dark:border-gray-800 flex justify-center">
          <div className="text-sm text-gray-600 dark:text-gray-400 font-light">
            © {new Date().getFullYear()} OpexAI. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
