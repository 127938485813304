import { Helmet } from 'react-helmet-async';
import Hero from '../components/Hero';
import DashboardPreview from '../components/DashboardPreview';
import CTA from '../components/CTA';
import Product from '../components/Product';
import Pricing from '../components/Pricing';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>OpexAI | AI-Driven Analytics & Automation Platform</title>
        <meta name="description" content="Transform your business operations with OpexAI's AI-powered analytics and automation platform. Get real-time insights, automate workflows, and drive operational excellence." />
        <meta name="keywords" content="AI analytics, business automation, operational excellence, machine learning, business intelligence, OpexAI, real-time insights, workflow automation" />
        
        {/* Open Graph / Social Media */}
        <meta property="og:title" content="OpexAI | AI-Driven Analytics & Automation Platform" />
        <meta property="og:description" content="Transform your business operations with AI-powered analytics and automation. Get real-time insights and optimize your processes with OpexAI." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://opexai.com" />
        <meta property="og:image" content="https://opexai.com/og-image.jpg" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="OpexAI | AI-Driven Analytics & Automation" />
        <meta name="twitter:description" content="Transform your business operations with AI-powered analytics and automation." />
        <meta name="twitter:image" content="https://opexai.com/twitter-card.jpg" />
        
        {/* Canonical URL */}
        <link rel="canonical" href="https://opexai.com" />
        
        {/* Additional SEO */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="OpexAI" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SoftwareApplication",
              "name": "OpexAI",
              "applicationCategory": "BusinessApplication",
              "description": "AI-powered analytics and automation platform for business operations",
              "operatingSystem": "Web-based",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
              }
            }
          `}
        </script>
      </Helmet>    
      
      <Hero />
      <DashboardPreview />
      <Product />
      <Pricing />
      <CTA />
    </>
  );
}
