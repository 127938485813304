import CTA from '../components/CTA';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import VideoShowcase from '../components/VideoShowcase';
import {
  CpuChipIcon,
  CircleStackIcon,
  GlobeAltIcon,
  BoltIcon,
  ChartBarIcon,
  UserGroupIcon,
  SparklesIcon,
  ArrowTrendingUpIcon,
  CommandLineIcon
} from '@heroicons/react/24/outline';


const platformFeatures = [
    {
      icon: CircleStackIcon,
      title: "360° Business Insights",
      description: "Unified view of all business metrics and KPIs"
    },
    {
      icon: CpuChipIcon,
      title: "AI-Powered Automation",
      description: "Smart workflows that adapt to your business needs"
    },
    {
      icon: ChartBarIcon,
      title: "Predictive Analytics",
      description: "Forward-looking insights for better decision making"
    },
    {
      icon: UserGroupIcon,
      title: "Team Collaboration",
      description: "Enhanced productivity through AI-assisted coordination"
    },
    {
      icon: SparklesIcon,
      title: "Smart Recommendations",
      description: "Context-aware suggestions for process optimization"
    },
    {
      icon: BoltIcon,
      title: "Automated Reporting",
      description: "Generate comprehensive reports automatically"
    },
    {
      icon: ArrowTrendingUpIcon,
      title: "Performance Tracking",
      description: "Real-time monitoring of key metrics"
    },
    {
      icon: CommandLineIcon,
      title: "Custom Workflows",
      description: "Build and automate custom business processes"
    },
    {
      icon: GlobeAltIcon,
      title: "Global Operations",
      description: "Manage operations across multiple locations"
    }
  ];
  
  const extensionFeatures = [
    {
      icon: GlobeAltIcon,
      title: "Universal Access",
      description: "Get insights anywhere on the web"
    },
    {
      icon: BoltIcon,
      title: "Real-time Analysis",
      description: "Instant data processing and recommendations"
    },
    {
      icon: SparklesIcon,
      title: "AI Copilot",
      description: "Context-aware assistance and suggestions"
    },
    {
      icon: ChartBarIcon,
      title: "Quick Analytics",
      description: "Instant data visualization and analysis"
    },
    {
      icon: CpuChipIcon,
      title: "Smart Actions",
      description: "One-click automated workflows"
    },
    {
      icon: ArrowTrendingUpIcon,
      title: "Progress Tracking",
      description: "Monitor your goals and achievements"
    }
  ];
  function FeatureCarousel({ features, itemsPerPage = 3 }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const totalPages = Math.ceil(features.length / itemsPerPage);
    
    useEffect(() => {
      if (!isPaused) {
        const timer = setInterval(() => {
          setCurrentPage((prev) => (prev + 1) % totalPages);
        }, 5000); // 4 seconds interval
  
        return () => clearInterval(timer);
      }
    }, [isPaused, totalPages]);
  
    return (
      <div 
        className="relative overflow-hidden"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={currentPage}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            className="space-y-6"
          >
            {features
              .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
              .map((feature) => (
                <div key={feature.title} className="flex items-start">
                  <feature.icon className="h-6 w-6 text-primary-500" />
                  <div className="ml-4">
                    <h3 className="text-white font-light">{feature.title}</h3>
                    <p className="text-gray-400 font-light">{feature.description}</p>
                  </div>
                </div>
              ))}
          </motion.div>
        </AnimatePresence>
        <div className="flex justify-center mt-6 space-x-2">
          {Array.from({ length: totalPages }).map((_, idx) => (
            <button
              key={idx}
              onClick={() => setCurrentPage(idx)}
              className={`w-2 h-2 rounded-full transition-all ${
                currentPage === idx ? 'bg-primary-500 w-4' : 'bg-gray-600'
              }`}
            />
          ))}
        </div>
      </div>
    );
  }
  
const processes = [
  {
    number: '01',
    title: 'Connect Your Tools',
    description: 'Integrate your existing tech stack with our platform. OpexAI seamlessly connects with your CRM, communication tools, and operational software.'
  },
  {
    number: '02',
    title: 'Configure AI Agents',
    description: 'Enable specialized AI agents through our Copilot interface. Each agent is trained on your data and optimized for specific operational tasks.'
  },
  {
    number: '03',
    title: 'Monitor & Optimize',
    description: 'Track performance through real-time dashboards. Our AI continuously learns and adapts, suggesting improvements for enhanced efficiency.'
  },
  {
    number: '04',
    title: 'Scale Operations',
    description: 'Expand your automated processes across departments. Scale your business operations while maintaining consistency and quality.'
  }
];

export default function ProductPage() {
  
  return (
    
 <>
      <Helmet>
        <title>OpexAI Platform | AI-Driven Analytics & Automation Suite</title>
        <meta name="description" content="Experience OpexAI's comprehensive suite of AI-powered tools. From real-time analytics to intelligent automation, transform your operations with cutting-edge technology." />
        
        {/* Open Graph / Social Media */}
        <meta property="og:title" content="OpexAI Platform | AI-Driven Analytics & Automation Suite" />
        <meta property="og:description" content="Discover our complete AI platform featuring real-time analytics, workflow automation, and predictive insights for operational excellence." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://opexai.com/product" />
        <meta property="og:image" content="https://opexai.com/images/product-overview.jpg" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="OpexAI Platform | Complete AI Solution" />
        <meta name="twitter:description" content="Transform your operations with our comprehensive AI-powered platform." />
        <meta name="twitter:image" content="https://opexai.com/images/product-twitter.jpg" />
        
        {/* Additional SEO */}
        <meta name="keywords" content="AI platform, business analytics, workflow automation, operational intelligence, predictive analytics, real-time insights, business optimization, OpexAI features" />
        <link rel="canonical" href="https://opexai.com/product" />
        <meta name="robots" content="index, follow" />
        
        {/* Schema.org markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "OpexAI Platform",
              "description": "AI-powered analytics and automation platform for business operations",
              "brand": {
                "@type": "Brand",
                "name": "OpexAI"
              },
              "category": "Business Software",
              "features": [
                "Real-time Analytics",
                "Workflow Automation",
                "AI-Powered Insights",
                "Predictive Analytics",
                "Process Optimization"
              ]
            }
          `}
        </script>
      </Helmet>
      <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      {/* Hero Section with Animated Gradient */}
      <section className="pt-32 pb-16 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-secondary-500/10 animate-gradient-x"></div>
        <div 
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: 'linear-gradient(to right, rgb(255 255 255 / 0.1) 1px, transparent 1px), linear-gradient(to bottom, rgb(255 255 255 / 0.1) 1px, transparent 1px)',
            backgroundSize: '40px 40px'
          }}
        ></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <span className="inline-block px-4 py-1 mb-6 text-xs font-light text-primary-400 bg-primary-500/10 rounded-full">
              Introducing OpexAI Platform
            </span>
            <h1 className="text-6xl font-light text-white mb-6 tracking-tight">
              Transform Your Operations with{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-primary-400 via-secondary-400 to-primary-400 animate-gradient-x">
                AI-Powered Intelligence
              </span>
            </h1>
            <p className="text-xl font-light text-gray-300 max-w-3xl mx-auto leading-relaxed">
              Experience the next generation of business optimization with our comprehensive suite of AI tools.
            </p>
          </motion.div>
        </div>
      </section>

{/* Platform Showcase */}
<section className="py-24 relative overflow-hidden">
  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/0 via-primary-900/10 to-gray-900/0"></div>
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
    <div className="grid lg:grid-cols-2 gap-12 items-center">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="relative z-10"
      >
        <span className="inline-block px-4 py-1 mb-6 text-xs font-light text-primary-400 bg-primary-500/10 rounded-full">
          Platform Overview
        </span>
        <h2 className="text-4xl font-light text-white mb-6">
          OpexAI Platform
          <span className="block text-lg text-primary-400 mt-2">Your Command Center for Business Excellence</span>
        </h2>
        <p className="text-gray-300 font-light mb-8 text-lg">
          Our platform brings together data analytics, automation, and AI-driven insights to optimize your business operations.
        </p>
        <div className="backdrop-blur-xl bg-gray-900/30 rounded-2xl p-8 border border-gray-700/30">
          <FeatureCarousel features={platformFeatures} />
        </div>
      </motion.div>
      
      <motion.div
  initial={{ opacity: 0, x: 20 }}
  whileInView={{ opacity: 1, x: 0 }}
  viewport={{ once: true }}
  className="relative z-10"
>
  <VideoShowcase 
    gifSrc={`${process.env.PUBLIC_URL}/assets/platform.gif`}
    videoSrc={`${process.env.PUBLIC_URL}/assets/platform.mp4`}
    title="OpexAI Platform Demo"
  />
</motion.div>
    </div>
  </div>
  
  {/* Background Elements */}
  <div className="absolute inset-0 overflow-hidden pointer-events-none">
    <div className="absolute -top-1/2 -right-1/2 w-full h-full bg-gradient-conic from-primary-500/20 via-secondary-500/20 to-primary-500/20 blur-3xl opacity-30 animate-slow-spin"></div>
    <div className="absolute -bottom-1/2 -left-1/2 w-full h-full bg-gradient-conic from-secondary-500/20 via-primary-500/20 to-secondary-500/20 blur-3xl opacity-30 animate-slow-spin-reverse"></div>
  </div>
</section>

{/* Browser Extension */}
<section className="py-24 bg-gray-800/50">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="grid lg:grid-cols-2 gap-12 items-center">
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="order-2 lg:order-1"
      >
        <VideoShowcase 
    gifSrc={`${process.env.PUBLIC_URL}/assets/extension.gif`}
    videoSrc={`${process.env.PUBLIC_URL}/assets/extension.mp4`}
          title="OpexAI Browser Extension Demo"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="order-1 lg:order-2"
      >
        <h2 className="text-4xl font-light text-white mb-6">
          Browser Extension
          <span className="block text-lg text-primary-400 mt-2">AI-Powered Insights at Your Fingertips</span>
        </h2>
        <p className="text-gray-300 font-light mb-8">
          Access powerful insights and AI assistance directly in your browser. Transform any webpage into a source of actionable business intelligence.
        </p>
        <FeatureCarousel features={extensionFeatures} />
      </motion.div>
    </div>
  </div>
</section>

      {/* Process Flow */}
      <section className="py-24 relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="text-4xl font-light text-white mb-4">
                Seamless Integration Process
              </h2>
              <p className="text-xl font-light text-gray-300">
                Get started in minutes with our intuitive setup
              </p>
            </motion.div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {processes.map((process, index) => (
              <motion.div
                key={process.number}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="group relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-primary-500/20 to-secondary-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-300"></div>
                <div className="relative bg-gray-800/40 backdrop-blur-xl rounded-2xl p-8 border border-gray-700/50 group-hover:border-primary-500/50 transition-all duration-300">
                  <span className="text-5xl font-light bg-gradient-to-r from-primary-500 to-secondary-500 text-transparent bg-clip-text mb-6 block">
                    {process.number}
                  </span>
                  <h3 className="text-xl font-light text-white mb-3">{process.title}</h3>
                  <p className="text-gray-300 font-light">{process.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      <CTA />
    </div>
    </>
  );
}
