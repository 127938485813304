import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import MobileMenu from './navigation/MobileMenu';

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const homeNavItems = [
    { name: 'Product', href: '#product' },
    { name: 'Pricing', href: '#pricing' },
    { 
      name: 'Resources',
      submenu: [
        { name: 'Contact', href: '/contact' },
        { name: 'Solutions', href: '/solutions' },
        { name: 'Browser Extension', href: '/extension' },
        { name: 'Knowledge', href: '/knowledge' }
      ]
    }
  ];
  
  const pageNavItems = [
    { name: 'Home', href: '/' },
    { name: 'Product', href: '/product' },
    { 
      name: 'Resources',
      submenu: [
        { name: 'Contact', href: '/contact' },
        { name: 'Solutions', href: '/solutions' },
        { name: 'Browser Extension', href: '/extension' },
        { name: 'Knowledge', href: '/knowledge' }
      ]
    }
  ];

  function NavItem({ item }) {
    const [showSubmenu, setShowSubmenu] = useState(false);
    const timeoutRef = useRef(null);
    const location = useLocation();
    const isHomePage = location.pathname === '/';
  
    const handleMouseEnter = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setShowSubmenu(true);
    };
  
    const handleMouseLeave = () => {
      timeoutRef.current = setTimeout(() => {
        setShowSubmenu(false);
      }, 150);
    };
  
    const handleNavigation = (href) => {
      if (isHomePage && href.startsWith('#')) {
        const element = document.getElementById(href.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
  
    return (
      <div 
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {item.submenu ? (
          <div>
            <span className="font-extralight text-gray-300 hover:text-primary-500 transition-colors duration-200 cursor-pointer">
              {item.name}
            </span>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: showSubmenu ? 1 : 0, y: showSubmenu ? 0 : 10 }}
              className="absolute top-full left-0 mt-2 py-2 w-48 bg-gray-900/95 backdrop-blur-lg rounded-lg shadow-xl"
              style={{ display: showSubmenu ? 'block' : 'none', pointerEvents: showSubmenu ? 'auto' : 'none' }}
            >
              {item.submenu.map((subItem) => (
                <Link
                  key={subItem.name}
                  to={subItem.href}
                  className="block px-4 py-2 text-sm font-extralight text-gray-300 hover:text-primary-500 transition-colors duration-200"
                >
                  {subItem.name}
                </Link>
              ))}
            </motion.div>
          </div>
        ) : (
          <Link
            to={item.href}
            onClick={(e) => {
              if (isHomePage && item.href.startsWith('#')) {
                e.preventDefault();
                handleNavigation(item.href);
              }
            }}
            className="font-extralight text-gray-300 hover:text-primary-500 transition-colors duration-200"
          >
            {item.name}
          </Link>
        )}
      </div>
    );
  }
  
  const currentNavItems = isHomePage ? homeNavItems : pageNavItems;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled ? 'bg-gray-900/80 backdrop-blur-lg border-b border-gray-800/50' : 'bg-transparent'
    }`}>
      <nav className="h-16 md:h-18 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-full">
          <div className="md:hidden order-first">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-300"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>

          <div className="w-[300px] flex-shrink-0 flex justify-center md:justify-start order-2 md:order-first">
            <Link to="/" className="flex items-center">
              <img 
                src="/logo.png" 
                alt="OpexAI" 
                className="h-8 md:h-9 lg:h-10 w-auto"
              />
            </Link>
          </div>

          <div className="hidden md:flex flex-1 items-center justify-center space-x-8">
            {currentNavItems.map((item) => (
              <NavItem key={item.name} item={item} />
            ))}
          </div>

          <div className="hidden md:flex items-center justify-end space-x-4 w-[300px]">
            <Link to="/trial">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-6 py-2 rounded-full bg-primary-500 text-white font-extralight shadow-lg shadow-primary-500/25 hover:shadow-primary-500/35 transition-all duration-300"
              >
                Free Trial
              </motion.button>
            </Link>
            <Link to="/trial">
              <motion.button
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-6 py-2 rounded-full border border-primary-500 text-primary-500 font-extralight hover:bg-primary-500/10 transition-all duration-300"
              >
                Login
              </motion.button>
            </Link>
          </div>
        </div>
      </nav>

      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </header>
  );
}
