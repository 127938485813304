import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

export default function MobileMenu({ isOpen, setIsOpen }) {
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname, setIsOpen]);

  const menuItems = [
    { path: '/', label: 'Home' },
    { path: '/product', label: 'Product' },
    { path: '/solutions', label: 'Solutions' },
    { path: '/integrations', label: 'Integrations' },
    { path: '/knowledge', label: 'Knowledge Base' },
    { path: '/roadmap', label: 'Roadmap' },
    { path: '/contact', label: 'Contact' },
  ];

  const menuVariants = {
    closed: {
      opacity: 0,
      x: '-100%',
      transition: {
        duration: 0.3
      }
    },
    open: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3
      }
    }
  };

  const MotionLink = motion.create(Link);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={() => setIsOpen(false)}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[45]"
          />
          
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            transition={{ duration: 0.3 }}
            className="fixed top-0 left-0 bottom-0 w-[300px] bg-gray-900/95 backdrop-blur-xl border-r border-gray-800 z-[50] p-6"
          >
            <div className="flex flex-col h-full">
              <div className="flex justify-between items-center mb-8">
                <h2 className="text-xl font-light text-white">Menu</h2>
                <button
                  onClick={() => setIsOpen(false)}
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                >
                  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <nav className="flex-1">
                <ul className="space-y-4">
                  {menuItems.map((item) => (
                    <li key={item.path}>
                      <MotionLink
                        to={item.path}
                        className="block py-2 text-gray-300 hover:text-white transition-colors"
                        whileHover={{ x: 4 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        {item.label}
                      </MotionLink>
                    </li>
                  ))}
                </ul>
              </nav>

              <div className="mt-auto pt-8 space-y-4">
                <MotionLink
                  to="/demo"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="block w-full px-6 py-3 bg-gradient-to-r from-primary-500 to-secondary-500 text-white text-center rounded-lg hover:from-primary-600 hover:to-secondary-600 transition-all duration-200"
                >
                  Book Demo
                </MotionLink>
                <MotionLink
                  to="/trial"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="block w-full px-6 py-3 border border-gray-700 text-white text-center rounded-lg hover:bg-gray-800 transition-all duration-200"
                >
                  Start Trial
                </MotionLink>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
