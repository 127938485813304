import { useState, useRef } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function VideoShowcase({ gifSrc, videoSrc, title }) {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const videoRef = useRef(null);

  const handleGifClick = () => {
    console.log('Gif clicked');
    setIsVideoModalOpen(true);
    setTimeout(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.currentTime = 0;
        const playPromise = videoElement.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.log('Auto-play prevented:', error);
          });
        }
      }
    }, 100);
  };

  return (
    <div className="bg-gradient-to-r from-secondary-500/20 to-primary-500/20 rounded-2xl p-1">
      <div className="bg-gray-800 rounded-2xl p-8">
        <div 
          className="aspect-video rounded-lg overflow-hidden cursor-pointer relative"
          onClick={handleGifClick}
        >
          <img 
            src={gifSrc}
            alt={title}
            className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
            <div className="bg-white/20 backdrop-blur-md p-3 rounded-full">
              <svg className="w-8 h-8 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isVideoModalOpen}
        onRequestClose={() => setIsVideoModalOpen(false)}
        className="max-w-5xl mx-auto mt-20 bg-gray-900/95 backdrop-blur-xl rounded-2xl p-1"
        overlayClassName="fixed inset-0 bg-black/90 z-50 backdrop-blur-sm"
      >
        <div className="relative aspect-video">
          <button 
            onClick={() => setIsVideoModalOpen(false)}
            className="absolute -top-12 right-0 text-white/80 hover:text-white transition-colors"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <video 
            ref={videoRef}
            autoPlay 
            controls
            className="w-full h-full rounded-xl"
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
      </Modal>
    </div>
  );
}
