import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import MainLayout from './layouts/MainLayout';
import ContactPage from './pages/Contact';
import ProductPage from './pages/Product';
import { ThemeProvider } from './contexts/ThemeContext';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Home from './pages/Home';
import IntegrationsPage from './pages/Integrations';
import RoadmapPage from './pages/Roadmap';
import ExtensionPage from './pages/Extension';
import KnowledgePage from './pages/Knowledge';
import Demo from './pages/Demo';
import Trial from './pages/Trial';
import SolutionsPage from './pages/Solutions';
import CookieConsent from './components/CookieConsent';

export default function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <Routes>
          <Route path="/" element={<MainLayout><Home /></MainLayout>} />
          <Route path="/contact" element={<MainLayout><ContactPage /></MainLayout>} />
          <Route path="/product" element={<MainLayout><ProductPage /></MainLayout>} />
          <Route path="/privacy" element={<MainLayout><Privacy /></MainLayout>} />
          <Route path="/terms" element={<MainLayout><Terms /></MainLayout>} />
          <Route path="/integrations" element={<MainLayout><IntegrationsPage /></MainLayout>} />
          <Route path="/roadmap" element={<MainLayout><RoadmapPage /></MainLayout>} />
          <Route path="/extension" element={<MainLayout><ExtensionPage /></MainLayout>} />
          <Route path="/knowledge" element={<MainLayout><KnowledgePage /></MainLayout>} />
          <Route path="/demo" element={<MainLayout><Demo /></MainLayout>} />
          <Route path="/trial" element={<MainLayout><Trial /></MainLayout>} />
          <Route path="/solutions" element={<MainLayout><SolutionsPage /></MainLayout>} />
        </Routes>
        <CookieConsent />
      </ThemeProvider>
    </HelmetProvider>
  );
}
