import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import PageHero from '../components/PageHero';
import ClosedBetaForm from '../components/ClosedBetaForm';

export default function Knowledge() {
  return (
    <>
          <Helmet>
        <title>OpexAI Knowledge Hub | AI Operations Learning Center</title>
        <meta name="description" content="Access comprehensive resources about AI-driven operations. Learn best practices, implementation guides, and stay updated with the latest in operational intelligence." />
        
        {/* Open Graph / Social Media */}
        <meta property="og:title" content="OpexAI Knowledge Hub | AI Operations Learning Center" />
        <meta property="og:description" content="Discover AI operational excellence through our comprehensive knowledge base. Access guides, tutorials, and best practices." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://opexai.com/knowledge" />
        <meta property="og:image" content="https://opexai.com/images/knowledge-hub.jpg" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="OpexAI Knowledge Hub | Learn AI Operations" />
        <meta name="twitter:description" content="Your gateway to understanding AI-powered operational excellence. Access comprehensive learning resources." />
        <meta name="twitter:image" content="https://opexai.com/images/knowledge-twitter.jpg" />
        
        {/* Additional SEO */}
        <meta name="keywords" content="AI operations, knowledge base, operational intelligence, learning resources, implementation guides, best practices, tutorials, OpexAI documentation" />
        <link rel="canonical" href="https://opexai.com/knowledge" />
        <meta name="robots" content="index, follow" />
        
        {/* Schema.org markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "OpexAI Knowledge Hub",
              "description": "Comprehensive learning resources for AI-driven operations",
              "provider": {
                "@type": "Organization",
                "name": "OpexAI",
                "url": "https://opexai.com"
              },
              "about": {
                "@type": "Thing",
                "name": "AI Operations Knowledge",
                "description": "Educational resources about AI-powered operational excellence"
              }
            }
          `}
        </script>
      </Helmet>
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <PageHero 
        subtitle="Knowledge Hub"
        title="Learn & Grow with"
        highlightedText="OpexAI"
        description="Discover how AI is transforming business operations and stay updated with our latest developments."
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 gap-12 items-start">
          {/* Content Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-8"
          >
            <div className="prose prose-invert">
              <h2 className="text-3xl font-light text-white">Coming Soon</h2>
              <div className="bg-gradient-to-r from-primary-500/10 to-secondary-500/10 rounded-xl p-6 backdrop-blur-sm">
              <h3 className="text-xl font-light text-white mb-3">Alpha Testing Phase</h3>
              <p className="text-gray-400 font-light">
                We're currently fine-tuning our platform with selected alpha users. Your early participation and feedback will be invaluable in creating the most effective operational intelligence solution.
              </p>
            </div>
              <p className="text-gray-300 font-light text-lg">
                Soon, you'll have access to:
              </p>
              <ul className="text-gray-300 font-light space-y-4">
                <li>Comprehensive guides and tutorials</li>
                <li>Best practices for AI-driven operations</li>
                <li>Integration documentation</li>
                <li>Case studies and success stories</li>
                <li>API documentation</li>
                <li>Video tutorials and webinars</li>
              </ul>
              <p className="text-gray-300 font-light text-lg mt-6">
                Join our closed beta program to be among the first to access these resources and help shape the future of operational intelligence.
              </p>
            </div>


          </motion.div>

          {/* Beta Signup Form */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="sticky top-24"
          >
            <ClosedBetaForm />
          </motion.div>
        </div>
      </div>
    </div>
    </>
  );
}
