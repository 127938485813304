import { useState, useEffect } from 'react';

export const useFormAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState({});

  useEffect(() => {
    // Get HubSpot context
    const hsContext = {
      pageUrl: window.location.href,
      pageName: document.title,
      hutk: document.cookie.match(/(^|; )hubspotutk=([^;]*)/) ? RegExp.$2 : null,
      pageId: window.location.pathname,
      ipAddress: null // HubSpot will populate this server-side
    };

    setAnalyticsData({
      userAgent: navigator.userAgent,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language,
      timestamp: new Date().toISOString(),
      context: hsContext
    });
  }, []);

  const enhanceSubmission = (fields) => {
    return {
      fields,
      context: analyticsData.context,
      legalConsentOptions: { // Required for GDPR compliance
        legitimateInterest: {
          value: true,
          subscriptionTypeId: 999,
          legalBasis: "Customer explicitly requested demo",
          text: "We're processing your information to deliver the requested service."
        }
      }
    };
  };

  return { enhanceSubmission };
};
