import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHero from '../components/PageHero';

export default function Privacy() {
  return (
    <>
          <Helmet>
        <title>Privacy Policy | OpexAI - Data Protection & Security</title>
        <meta name="description" content="Learn how OpexAI protects your data and ensures compliance with global privacy regulations. Understand our commitment to data security and user privacy." />
        
        {/* Open Graph / Social Media */}
        <meta property="og:title" content="Privacy Policy | OpexAI - Data Protection & Security" />
        <meta property="og:description" content="Discover OpexAI's comprehensive privacy policy and data protection measures. Learn how we safeguard your information and maintain compliance." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://opexai.com/privacy" />
        <meta property="og:image" content="https://opexai.com/images/privacy-policy.jpg" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="OpexAI Privacy Policy | Data Protection" />
        <meta name="twitter:description" content="Understanding OpexAI's commitment to data privacy and security measures." />
        <meta name="twitter:image" content="https://opexai.com/images/privacy-twitter.jpg" />
        
        {/* Additional SEO */}
        <meta name="keywords" content="privacy policy, data protection, GDPR compliance, data security, user privacy, information security, OpexAI privacy, data handling" />
        <link rel="canonical" href="https://opexai.com/privacy" />
        <meta name="robots" content="index, follow" />
        
        {/* Schema.org markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "OpexAI Privacy Policy",
              "description": "Privacy policy and data protection information for OpexAI services",
              "publisher": {
                "@type": "Organization",
                "name": "OpexAI",
                "url": "https://opexai.com"
              },
              "about": {
                "@type": "Thing",
                "name": "Data Privacy",
                "description": "Information about data protection and privacy practices"
              },
              "dateModified": "2024-01-01"
            }
          `}
        </script>
      </Helmet>
      <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 mb-12">
        <PageHero 
          subtitle="Last updated: January 2024"
          title="Privacy Policy"
        />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="prose dark:prose-invert prose-lg max-w-none">
            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Introduction</h2>
              <p className="text-gray-300 mb-4">OPEXAI LTD ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website and use our services.</p>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Company Information</h2>
              <p className="text-gray-300 mb-4">OPEXAI LTD is registered in England and Wales.</p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Company Number: 15924301</li>
                <li>Registered Office Address: 5 Technology Park, Colindeep Lane, Colindale, London, United Kingdom, NW9 6BX</li>
              </ul>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Data Collection & Usage</h2>
              <p className="text-gray-300 mb-4">We collect information that you provide directly to us, including:</p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Contact information</li>
                <li>Usage data and analytics</li>
                <li>Communication preferences</li>
                <li>Service usage information</li>
              </ul>
            </section>
            <section className="mb-16">
  <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Browser Extension Privacy</h2>
  
  <div className="space-y-8">
    <div>
      <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Data Access & Processing</h3>
      <ul className="list-disc pl-6 text-gray-300 space-y-2">
        <li>Text highlighting and analysis functionality</li>
        <li>Integration with authorized third-party platforms (CRM, accounting, communication tools)</li>
        <li>Local storage of user preferences and configurations</li>
        <li>Real-time AI processing of selected content</li>
      </ul>
    </div>

    <div>
      <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Data Storage & Security</h3>
      <ul className="list-disc pl-6 text-gray-300 space-y-2">
        <li>Local storage of user preferences and session data</li>
        <li>No remote code execution</li>
        <li>Encrypted communication with authorized platforms</li>
        <li>Regular security audits and updates</li>
      </ul>
    </div>

    <div>
      <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Host Permissions</h3>
      <p className="text-gray-300 mb-4">Our extension requires specific host permissions to:</p>
      <ul className="list-disc pl-6 text-gray-300 space-y-2">
        <li>Access connected CRM platforms</li>
        <li>Integrate with accounting software</li>
        <li>Connect with communication tools (Slack, Google Workspace)</li>
        <li>Process highlighted text for analysis</li>
      </ul>
    </div>

    <div>
      <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Data Retention</h3>
      <ul className="list-disc pl-6 text-gray-300 space-y-2">
        <li>User preferences stored locally until explicitly cleared</li>
        <li>Session data cleared upon browser closure</li>
        <li>Integration tokens managed securely</li>
        <li>No permanent storage of analyzed content</li>
      </ul>
    </div>

    <div className="bg-gray-800 p-6 rounded-xl">
      <p className="text-sm text-gray-300">
        The OpexAI Browser Extension is designed with privacy-first principles, ensuring all data processing is transparent, secure, and compliant with relevant data protection regulations.
      </p>
    </div>
  </div>
</section>
            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">AI Processing & Analytics</h2>
              <p className="text-gray-300 mb-4">Our AI-driven analytics and automation services process data in the following ways:</p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Automated data collection from integrated business systems</li>
                <li>Machine learning algorithms for pattern recognition and predictions</li>
                <li>Real-time analytics processing for business intelligence</li>
                <li>Automated report generation and insights delivery</li>
              </ul>
              
              <div className="mt-8">
                <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Data Processing Safeguards</h3>
                <ul className="list-disc pl-6 text-gray-300 space-y-2">
                  <li>Data anonymization and pseudonymization where applicable</li>
                  <li>Secure API endpoints with encryption</li>
                  <li>Regular security audits and penetration testing</li>
                  <li>Access controls and authentication protocols</li>
                  <li>Data retention policies aligned with business needs</li>
                </ul>
              </div>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Cookies & Tracking</h2>
              <p className="text-gray-300 mb-4">Our website uses the following tracking and functionality services:</p>
              
              <div className="space-y-8 mt-6">
                <div>
                  <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Essential Services</h3>
                  <ul className="list-disc pl-6 text-gray-300 space-y-2">
                    <li>IP address tracking for security and geo-location services</li>
                    <li>Session cookies for website functionality</li>
                    <li>Authentication tokens for secure access</li>
                  </ul>
                </div>
                <div>
  <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Extension Permissions</h3>
  <ul className="list-disc pl-6 text-gray-300 space-y-2">
    <li>Context menu access for text selection features</li>
    <li>Storage access for user preferences</li>
    <li>Host permissions for integrated platforms</li>
    <li>Side panel functionality for data display</li>
  </ul>
</div>
                <div>
                  <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Third-Party Services</h3>
                  <ul className="list-disc pl-6 text-gray-300 space-y-2">
                    <li>HubSpot forms for contact and newsletter subscriptions</li>
                    <li>HubSpot analytics for visitor tracking and engagement</li>
                    <li>Google Calendar integration for meeting scheduling</li>
                    <li>Google Analytics for website performance monitoring</li>
                  </ul>
                </div>

                <div className="bg-gray-800 p-6 rounded-xl">
                  <p className="text-sm text-gray-300">By using our website, you consent to the processing of data about you by the above-mentioned third-party services. You can control cookie preferences through your browser settings.</p>
                </div>
              </div>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">GDPR Compliance</h2>
              
              <div className="space-y-8">
                <div>
                  <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">EU Data Protection Rights</h3>
                  <p className="text-gray-300 mb-4">For EU residents, we ensure the following rights under GDPR:</p>
                  <ul className="list-disc pl-6 text-gray-300 space-y-2">
                    <li>Right to be informed about how your data is used</li>
                    <li>Right to access your personal data</li>
                    <li>Right to rectification of inaccurate data</li>
                    <li>Right to erasure ("right to be forgotten")</li>
                    <li>Right to restrict processing</li>
                    <li>Right to data portability</li>
                    <li>Right to object to processing</li>
                    <li>Rights related to automated decision making and profiling</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">International Data Transfers</h3>
                  <p className="text-gray-300 mb-4">When transferring data outside the EU/EEA, we implement:</p>
                  <ul className="list-disc pl-6 text-gray-300 space-y-2">
                    <li>Standard Contractual Clauses (SCCs)</li>
                    <li>Appropriate security measures</li>
                    <li>Regular data protection impact assessments</li>
                    <li>Transparency in data processing activities</li>
                  </ul>
                </div>

                <div className="bg-gray-800 p-6 rounded-xl">
                  <p className="text-gray-300">To exercise your GDPR rights or for any data protection inquiries, please contact our Data Protection Officer through our secure contact form or registered office address.</p>
                </div>
              </div>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Contact Us</h2>
              <p className="text-gray-300 mb-4">For privacy-related inquiries, you can reach our data protection team through our secure contact form on the website or by writing to our registered address.</p>
              <div className="bg-gray-800 p-6 rounded-xl mt-4">
                <p className="font-light text-gray-300">
                  Data Protection Team<br />
                  OPEXAI LTD<br />
                  5 Technology Park<br />
                  Colindeep Lane<br />
                  Colindale, London<br />
                  NW9 6BX<br />
                  United Kingdom
                </p>
              </div>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Updates to This Policy</h2>
              <p className="text-gray-300">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}