import { motion } from 'framer-motion';
import HubSpotForm from './ui/HubSpotForm';
import { CONFIG } from '../config/env';
import { useFormAnalytics } from './analytics/FormAnalyticsWrapper';

export default function BookDemoForm() {
  const { enhanceSubmission } = useFormAnalytics();

  const handleSubmit = (fields) => {
    return enhanceSubmission(fields);
  };

  return (
    <motion.div 
      className="bg-gray-800/50 backdrop-blur-xl rounded-xl p-8 border border-gray-700/50 w-full max-w-2xl"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      <h3 className="text-2xl font-light text-white mb-4">Schedule a Demo</h3>
      <p className="text-gray-400 font-light mb-8">
        Experience the power of OpexAI firsthand. Let us show you how our platform can transform your operations.
      </p>
      
      <HubSpotForm
        formId={CONFIG.hubspot.demoFormId}
        fields={[
          { name: "firstname", label: "First Name", required: true },
          { name: "lastname", label: "Last Name", required: true },
          { name: "email", label: "Email", type: "email", required: true },
          { name: "company", label: "Company", required: true },
          { 
            name: "company_size", 
            label: "Company Size", 
            type: "enumeration",
            options: [
              { value: "", label: "Please select" },
              { value: "1-5", label: "1-5" },
              { value: "5-25", label: "5-25" },
              { value: "25-50", label: "25-50" },
              { value: "50-100", label: "50-100" },
              { value: "100-500", label: "100-500" },
              { value: "500-1000", label: "500-1000" },
              { value: "1000+", label: "1000+" }
            ],
            required: true 
          },
          { name: "jobtitle", label: "Job Title", required: true },
          { 
            name: "area_of_interest", 
            label: "Areas of Interest", 
            type: "textarea", 
            placeholder: "Tell us which aspects of OpexAI you'd like to explore during the demo (e.g., specific features, use cases, or integration possibilities)",
            required: true 
          }
        ]}
        onSubmit={handleSubmit}
        submitButtonText="Request Demo"
      />
    </motion.div>
  );
}
