import { motion } from 'framer-motion';
import HubSpotForm from './ui/HubSpotForm';
import { CONFIG } from '../config/env';
import { useFormAnalytics } from './analytics/FormAnalyticsWrapper';

export default function ClosedBetaForm() {
  const { enhanceSubmission } = useFormAnalytics();

  const handleSubmit = (fields) => {
    return enhanceSubmission(fields);
  };

  return (
    <motion.div 
      className="bg-gray-800/50 backdrop-blur-xl rounded-xl p-8 border border-gray-700/50 w-full max-w-2xl"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      <h3 className="text-2xl font-light text-white mb-4">Join Closed Beta</h3>
      <p className="text-gray-400 font-light mb-8">
        We're currently in alpha testing and preparing to launch our closed beta program. Join the waitlist to be among the first to experience OpexAI's revolutionary browser extension.
      </p>
      
      <HubSpotForm
        formId={CONFIG.hubspot.closedBetaFormId}
        fields={[
          { name: 'firstname', label: 'Name', required: true },
          { name: 'email', label: 'Work Email', type: 'email', required: true },
          { name: 'company', label: 'Company', required: true },
          { 
            name: 'company_size', 
            label: 'Number of Employees', 
            type: 'enumeration',
            options: [
              { value: '', label: 'Please select' },
              { value: '1-5', label: '1-5' },
              { value: '5-25', label: '5-25' },
              { value: '25-50', label: '25-50' },
              { value: '50-100', label: '50-100' },
              { value: '100-500', label: '100-500' },
              { value: '500-1000', label: '500-1000' },
              { value: '1000+', label: '1000+' }
            ],
            required: true 
          }
        ]}
        onSubmit={handleSubmit}
        submitButtonText="Join Waitlist"
      />
    </motion.div>
  );
}
